import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import heroImg1 from "../assets/images/hero-img-1.png";
import heroImg2 from "../assets/images/hero-img-2.png";
import heroImg3 from "../assets/images/hero-img-3.png";
import heroImg4 from "../assets/images/hero-img-4.png";
import lendersServicers from "../assets/images/lenders-servicers-hand.png";
import heroImg5 from "../assets/images/hero-img-5.png";
import heroImg6 from "../assets/images/hero-img-6.png";
import about from "../assets/images/About Us Page.jpg";
import hero from "../assets/images/Yoloh_Yolo_transparent.png";
import aboutUs from "../assets/images/about-us-text-mascot-1.webp";
import target from "../assets/images/purpose.svg";
import Vision from "../assets/images/vision.svg";
import yolohimg from "../assets/images/banner-background.webp";
import partner1 from "../assets/images/p1.png";
import partner2 from "../assets/images/p2.jpeg";
import partner3 from "../assets/images/p3.png";
import darkicon1 from "../assets/images/d-i-1.svg";
import lighticon1 from "../assets/images/l-i-1.svg";
import darkicon2 from "../assets/images/d-i-2.svg";
import lighticon2 from "../assets/images/l-i-2.svg";
import darkicon3 from "../assets/images/d-i-3.svg";
import lighticon3 from "../assets/images/l-i-3.svg";
import darkicon4 from "../assets/images/d-i-4.svg";
import lighticon4 from "../assets/images/l-i-4.svg";
import e1 from "../assets/images/e-1.png";
import e2 from "../assets/images/e-2.png";
import e3 from "../assets/images/e-3.png";
import promise from "../assets/images/our-promise-2.webp";
import h1 from "../assets/images/h1.png";
import im1 from "../assets/images/Home.svg";
import im2 from "../assets/images/Auto.svg";
import im3 from "../assets/images/autoplushome.svg";
import im4 from "../assets/images/flood.svg";
import im5 from "../assets/images/umbrella.svg";
import im6 from "../assets/images/pet.svg";
import im7 from "../assets/images/Renter.svg";
import im8 from "../assets/images/autoplusrenters.svg";
import im9 from "../assets/images/warranty.svg";
import im10 from "../assets/images/life.svg";
import im11 from "../assets/images/Condo.svg";
import im12 from "../assets/images/autopluscondo.svg";
import mission from "../assets/images/mission.svg";
import Slider from "react-slick";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Landing.css";

import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { connectWebQuery } from "../http/services/api-payload-prepare";
import axios from "axios";
import { environment } from "../environment/environment";
import {
  handleEmailValidate,
  // handleMobileValidateForProfile,
} from "../validations";
import { enqueueSnackbar } from "notistack";
import Login from "../components/Login";
import { getLocalStorageValue } from "../http/services/local-storage-service";
const servicesCard = [
  {
    title: "Insurance Marketplace",
    describe:
      "Meet 'Andi', your genius personal shopper! No need to send current policies to retrieve quotes - Andi's got it covered!",
    darkimage: lighticon1,
    image: darkicon1,
    describe_1:
      "Meet 'Andi', your genius personal shopper! No need to send current policies to retrieve quotes - Andi's got it covered!",
    describe_2:
      "YOLOH will understand you and deploy the most advanced shopping engine to secure highly competitive quotes from our hand-picked nationwide agents and brokers. Rest assured you are working with the best professionals in the business.",
  },
  {
    title: "Seriously Smart Savings",
    describe:
      "Consumer watchdogs report billions in overpayments for insurance yearly. That's why YOLOH exists: 100% anti-overpay! Let's go!",
    darkimage: lighticon2,
    image: darkicon2,
    describe_1:
      "Consumer watchdogs report billions in overpayments for insurance yearly. That's why YOLOH exists: 100% anti-overpay! Let's go!",
    describe_2:
      "Via the most innovative pro-consumer platform ever, we find the right coverage for the best price. We pull in numerous insurance quotes, but only display the most competitive quotes from the best agents and brokers out there-reputable, vetted and experienced.",
  },
  {
    title: "Policy Vault",
    describe:
      "Our policy vault is a secure digital wallet to store and manage all your household’s important documents, beyond insurance policies. ",
    darkimage: lighticon3,
    image: darkicon3,
    describe_1:
      "Our policy vault is a secure digital wallet to store and manage all your household’s important documents, beyond insurance policies. It's a virtual safe for immediate access, anytime needed.",
    describe_2:
      "With your consent, YOLOH should be able to upload your existing insurance policies. If your carriers do not participate, you can easily upload your policies into the vault. Manage and store everything in one SECURE place. Share access with others.",
  },
  {
    title: "Insurance Verification",
    describe:
      "Financial institutions can close loans faster and smarter. With YOLOH, nobody has to chase down insurance agents or policy documents. We do that for you!",
    darkimage: lighticon4,
    image: darkicon4,
    describe_1:
      "Financial institutions can close loans faster and smarter. With YOLOH, nobody has to chase down insurance agents or policy documents. We do that for you!",
    describe_2:
      "You can now order insurance verifications as a product, just like VOE and VOI.",
    describe_3:
      "Better yet, we use Artificial Intel (AI) to automate and QC the verifications, finding errors between policies and your data, providing a ‘Pass/Fail’ report immediately. We can even deliver the full policy to you. ",
    describe_4:
      "Focus on your operations and not insurance. Eliminate errors, manual reviews and respective resources, closing delays, expense and frustration.",
  },
];
const insuranceMarketplace = [
  {
    title: "Home",
    subTitle: "Security and Savings for Your Sanctuary",
    description_1:
      "Where your home meets our helping hand (‘Andi’). YOLOH’s use of advanced technology, data and analytics ensures the coverage and savings you deserve.",
    description_2:
      "ADVICE: Use our Personal Private Vault to document your belongings. Catalog the evidence you’ll need for reimbursement.",
    image: im1,
  },
  {
    title: "Auto",
    subTitle: "Protect Your Wheels - and Wallet",
    description_1:
      "Large or small, cover them all with confidence. We’ll get you savings in a dash. All you’ll do is make a plan for your extra cash!",
    image: im2,
  },
  {
    title: "Auto + Home",
    subTitle: "Drive Safely, Live Securely; All in One",
    description_1:
      "Bundling multiple policies often gives you even more savings, sometimes referred to as a multipolicy, multiline or multiproduct discounts. Why not let insurance companies reward you for your loyalty?",
    image: im3,
  },
  {
    title: "Flood",
    subTitle: "Weather the Storm - Together",
    description_1:
      "FEMA says just 1-inch of water can cause $25,000 of damage.",
    description_2:
      "Should you insure the building only, or your belongings within, or maybe both? We have partnered with highly experienced agents and brokers that can guide you.",
    description_3:
      "Don’t wait for that storm just predicted. Almost all flood policies have a 30-day wait period.",
    image: im4,
  },
  {
    title: "Umbrella",
    subTitle: "Improves Your Ability to Protect Your Liability",
    description_1:
      "Your own SAFETY NET at very affordable prices, often just a few hundred dollars for $1 million per year in extra coverage.",
    description_2:
      "Get extra liability coverage beyond current policy limits for auto, home and others. Should you be sued, umbrella insurance can pay what you owe when you are at fault for personal injury or property damage. The best net you can get!",
    image: im5,
  },
  {
    title: "Pet",
    subTitle: "We’ve Got Your Pets Back - and Tail",
    description_1:
      "Per the NAPHIA, growth in pet insurance is unprecedented, nearly doubling since 2019. Why? The perceived value of pet (and wallet) protection cannot be denied.",
    description_2:
      "There are so many coverage options, from routine check-ups, accident and illness to catastrophic care. There are usually good choices on deductibles too. Customize to the right size!",
    image: im6,
  },
  {
    title: "Renters",
    subTitle: "Where Your Lease Meets Peace",
    description_1:
      "Your landlord covers the building. Everything else is ‘on you.’ Protect all your belongings. Replacement costs for your stuff are often WAY more costly than anticipated.",
    description_2:
      "Standard renter policies contain liability coverage which can pay damages if you’re responsible for accidentally injuring someone else or damaging their property. Legal costs are often covered too.",
    description_3:
      "ADVICE: Use our Personal Private Vault to document your belongings. Catalog the evidence you’ll need for reimbursement.",
    image: im7,
  },
  {
    title: "Auto + Renters",
    subTitle: "Your Keys. Your Lease; Our Insurance Expertise.",
    description_1:
      "Bundling multiple policies often gives you even more savings, sometimes referred to as a multipolicy, multiline or multiproduct discounts. Why not let insurance companies reward you for your loyalty?",
    image: im8,
  },
  {
    title: "Warranty",
    subTitle: "Gadgets, Gizmos and Gear; Cover the Cool Stuff",
    description_1:
      "Our warranty partners share our commitment - taking expert care of YOU with ‘just right coverage’ at reasonable prices.",
    description_2:
      "Phones, watches, electronics, appliances, grills, exercise gear and your other life essentials… see how easily you can get great protection.",
    image: im9,
  },
  {
    title: "Life",
    subTitle: "Insurance to Prove That Love Lives On",
    description_1:
      "A more secure tomorrow for the people that depend upon you today. Our lives end, but financial needs and existing debts continue on for our loved ones.",
    description_2:
      "You are unique. Let YOLOH’s one-of-a-kind solution find you a custom fit policy. There are several options, and countless nuances and policy ‘riders’ to consider. We’ll simplify it.",
    image: im10,
  },
  {
    title: "Condo",
    subTitle: "Covering Your Corner Of Condo World",
    description_1:
      "Condo insurance, a dependable friend for your home. Owning a condo is about more than just a space; it’s a place where your life unfolds.",
    description_2:
      "Think about it: your cozy haven, nestled within the condo walls, your slice of serenity amidst the bustling world. Now imagine a mishap – a fire, a break-in, or even a water leak. That’s where condo insurance steps in. It’s your sturdy shield, covering not just the physical structure but also your cherished possessions inside. From personal liability protection to loss assessment coverage, it’s got your back, ensuring you can continue enjoying your condo life, worry-free.",
    image: im11,
  },
  {
    title: "Auto + Condo",
    subTitle: "Ultimate Combo For Modern Living",
    description_1:
      "Bundling multiple policies often gives you even more savings, sometimes referred to as a multipolicy, multiline or multiproduct discounts. Why not let insurance companies reward you for your loyalty?",
    image: im12,
  },
];
const partner = [
  {
    title: "Agents, Brokers & Carriers",
    describe:
      "Get highly qualified leads, as our subscriber community has confirmed they are ‘in the market.’ They like and trust us to act in their best interest.",
    description_1:
      "Get highly qualified leads, as our subscriber community has confirmed they are ‘in the market.’ They like and trust us to act in their best interest.",
    description_2:
      "We will limit the number of leads we pass on to our insurance partners, so you won’t be competing with dozens. The more successful you are, the more we prosper. We understand your needs and expectations, having built the platform with you in mind.",
    description_3:
      "The Tiered Model: You subscribe to get qualified leads and reply with policy quotes, if desired. We get modest compensation on the volume of leads you get and win.",
  },
  {
    title: "Banks, Lenders & Servicers",
    describe:
      "YOLOH serves both the needs of you and your borrowers. We are the only provider to use technology to automate and QC insurance verifications, now an orderable product just like VOE and VOI.",
    description_1: "YOLOH serves both the needs of you and your borrowers.",
    description_2:
      "We are the only provider to use technology to automate and QC insurance verifications, now an orderable product just like VOE and VOI. In fact, you can stop chasing insurance agents and documents, manually reviewing policies and you can eliminate or reassign your related resources. Replace those fixed costs and only pay for ‘already reviewed’ insurance verifications delivered to you.",
    description_3:
      "We give your borrowers two options: 1) Upload their existing insurance policy for us to QC and verify, or 2) Leverage our online insurance marketplace, and/or expert agents, to shop for insurance in a most simplified way. As a bonus, when your borrower buys policies from YOLOH, your respective insurance verification is delivered to you free.",
    description_4:
      "Whether at the loan origination or servicing stage, individual or bulk transactions, we’ve got you covered.",
    note: [
      // {
      //   title: "YOLOH  -  A Digital Insurance Agency",
      //   step: [
      //     "Leverages advanced analytics, AI, machine learning and more.",
      //     "Instant Insurance Verification Summary, with nobody chasing it down.",
      //     "Ongoing post-close co-branding opportunities. (i.e., renewals, education, etc.)",
      //     "Stay engaged with the consumer through their life journey.",
      //   ],
      // },
      {
        title: "Empowers Borrowers",
        step: [
          "Focus on the loan process, not insurance hassles",
          "Insurance: No longer a follow-up task for borrower or lender. (speed)",
          "Instant quotes, choice and likely savings - from trusted partners.",
          "You are trusted for the loan; Extends trust and loyalty to insurance. (retention)",
          "1-stop concierge to buy and manage insurance portfolio and household risk.",
        ],
      },
    ],
  },
  {
    title: "Credit Unions",
    describe:
      "YOLOH serves both the needs of you and your borrowers. We are the only provider to use technology to automate and QC insurance verifications, now an orderable product just like VOE and VOI.",
    description_1: "YOLOH serves both the needs of you and your members.",
    description_2:
      "We are the only provider to use technology to automate and QC insurance verifications, now an orderable product just like VOE and VOI. In fact, you can stop chasing insurance agents and documents, manually reviewing policies and you can eliminate or reassign your related resources. Replace those fixed costs and only pay for ‘already reviewed’ insurance verifications delivered to you.",
    description_3:
      "We give your members two options: 1) Upload their existing insurance policy for us to QC and verify, or 2) Leverage our online insurance marketplace, and/or expert agents, to shop for insurance in a most simplified way. As a bonus, when your member buys policies from YOLOH, your respective insurance verification is delivered to you free.",
    description_4:
      "Whether at the loan origination or servicing stage, individual or bulk transactions, we’ve got you covered.",
    note: [
      // {
      //   title: "YOLOH  -  A Digital Insurance Agency",
      //   step: [
      //     "Leverages advanced analytics, AI, machine learning and more.",
      //     "Instant Insurance Verification Summary, with nobody chasing it down.",
      //     "Ongoing post-close co-branding opportunities. (i.e., renewals, education, etc.)",
      //     "Stay engaged with the member through their life journey.",
      //   ],
      // },
      {
        title: "Empowers Members",
        step: [
          "Focus on the loan process, not insurance hassles.",
          "Insurance: No longer a follow-up task for member or CU. (speed)",
          "Instant quotes, choice and likely savings from trusted partners.",
          "You are trusted for the loan; Extends trust and loyalty to insurance. (retention)",
          "1-stop concierge to buy and manage insurance portfolio and household risk.",
        ],
      },
    ],
  },
  {
    title: "Realtors",
    describe:
      "We share similar dispositions, surviving on relationships and reputation, and engaging with clients with a wide variety of needs and needed referrals. Give us a shot at your insurance referrals.",
    description_1:
      "We share similar dispositions, surviving on relationships and reputation, and engaging with clients with a wide variety of needs and needed referrals. Give us a shot at your insurance referrals.",
    description_2:
      "Neither you nor your clients will be disappointed, being assured of a great experience and value. Our nationwide search will deliver policy quotes from reputable and trusted agents, brokers, and/or carriers.",
    description_3:
      "Our model will focus on your brand exposure and loyalty long after the point-of-sale via periodic campaigns and at renewal time, as we honor YOU as being the original source of our mutual client subscriber. Nobody does insurance better!",
    note: [
      {
        title: "Empowers Borrowers",
        step: [
          "Focus on the home purchase, not insurance hassles.",
          "Insurance: No longer a dreaded follow-up client task.",
          "Instant quotes, choice and likely savings - from trusted partners.",
          "You are a trusted advisor; Extends trust and loyalty to insurance. (more touch-points, retention)",
          "Ongoing co-branding opportunities. (i.e., renewals, education, etc.)",
          "1-stop concierge to buy and manage insurance portfolio and household risk.",
        ],
      },
    ],
  },
  {
    title: "Others",
    describe:
      "Have warranty or other protection products or services to help consumers or small businesses? Let’s talk! We are always looking for risk mitigation solutions that work and drive loyalty for you and for us.",
    description_1:
      "Have warranty or other protection products or services to help consumers or small businesses? Let’s talk! We are always looking for risk mitigation solutions that work and drive loyalty for you and for us.",
    description_2:
      "Dynamic engagement. When our subscribers add purchases (household contents) to their eVault, we alert them to coverage options.",
    description_3:
      "Our model will focus on your brand exposure and loyalty long after the point of sale via periodic campaigns and at renewal time, as we honor YOU as being the original source of our mutual client subscriber. Nobody does risk mitigation and consumer protection better!",
    note: [
      {
        title: "YOLOH  -  A Digital Insurance Agency",
        step: [
          "Leverages advanced analytics, AI, machine learning and more.",
          "You are a trusted advisor; Extends trust and loyalty to insurance. (more touch-points, retention)",
          "Ongoing co-branding opportunities. (i.e., renewals, education, etc.)",
          "1-stop concierge to buy and manage insurance portfolio and household risk.",
        ],
      },
    ],
  },
];
const initialValues = {
  name: "",
  email: "",
  phone_number: "",
  category: "",
  description: "",
  website_tracking: "",
};
const Lending = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState({ show: false, record: {} });
  const [openPartner, setOpenPartner] = useState({ show: false, record: {} });
  const [value, setValue] = useState(initialValues);
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const [serviceType, setServiceType] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [openWhatWeOffer, setOpenWhatWeOffer] = useState({
    show: false,
    record: {},
  });
  const [openOffer, setOpenOffer] = useState({ show: false, record: {} });
  const handleCloseOffer = () => {
    setOpenWhatWeOffer({ show: false, record: {} });
  };
  const { authUser, setAuthUser, isLoggedIn } = useAuth();
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    pauseOnHover: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    if (isLoggedIn()) {
      // if (localStorage.hasOwnProperty("authUserValidated")) {
      if (getLocalStorageValue("authUserValidated") === false) {
        // navigate("/create-account");
      } else {
        // navigate("/overview");
      }
      // }
    }
    if (location?.hash) {
      scrollToDiv(location?.hash);
    }
  }, []);
  const handleClose = () => {
    setOpen({ show: false, record: {} });
  };
  const handleValidationError = () => {
    let error = {};
    error = {
      ...error,
      name: validationMassage("name", value?.name),
      email: validationMassage("email", value?.email),
      phone_number: validationMassage("phone_number", value?.phone_number),
      description: validationMassage("description", value?.description),
    };
    setError(error);
    if (
      error?.name?.length > 0 ||
      error?.email?.length > 0 ||
      error?.phone_number?.length > 0 ||
      error?.description?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handelConnectForm = async (e) => {
    e?.preventDefault();
    if (handleValidationError()) {
      return true;
    }
    setLoader(true);
    try {
      const response = await fetch(
        environment?.api_open_endpoint + "/open-api",
        {
          method: "POST",
          headers: {},
          body: JSON.stringify({
            header: { message_name: "web_queries-C" },
            body: {
              ...value,
              category: value?.category === "default" ? "" : value?.category,
            },
          }),
        }
      );
      if (response?.status === 200) {
        const responseData = await response.json();
        setValue(initialValues);
        setError({});
        if (responseData?.message) {
          enqueueSnackbar(responseData?.message, {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Something went wrong.!", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Something went wrong.!", {
          variant: "error",
        });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error, "error");
    }
  };
  const requiredField = ["name", "email", "phone_number", "description"];
  const EMAIL_REGEX = new RegExp(`^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`);
  const validationMassage = (name, value) => {
    switch (name) {
      case "name":
        if (value?.length > 0) {
          if (!/^(?!.*<[^>]+>).*$/.test(value)) {
            return "Please enter valid name";
          } else {
            return "";
          }
        } else {
          return "This field is required";
        }
      case "email":
        if (value?.length > 0) {
          if (EMAIL_REGEX.test(value)) {
            return "";
          } else {
            return "Please enter valid email";
          }
        } else {
          return "This field is required";
        }
      case "phone_number":
        if (value?.length > 0) {
          const updated = value.replace("+1", "").replace("+91", "");
          const TEN_DIGIT_PHONE_REGEX = new RegExp(`^[0-9]{10}$`);
          if (
            TEN_DIGIT_PHONE_REGEX.test(updated) &&
            (value.indexOf("+1") > -1 ||
              value.indexOf("+91") > -1 ||
              value.indexOf("") > -1)
          ) {
            return "";
          } else {
            return "Please enter valid Phone number";
          }
        } else {
          return "This field is required";
        }
      case "description":
        if (value?.length > 0) {
          if (!/^(?!.*<[^>]+>).*$/.test(value)) {
            return "Please enter valid description";
          } else {
            return "";
          }
        }
    }
  };
  const handelConnectField = (e) => {
    const { name, value } = e?.target;
    if (requiredField?.includes(name)) {
      setError((preError) => {
        return {
          ...preError,
          [name]: validationMassage(name, value),
        };
      });
    }
    setValue((preVal) => {
      return { ...preVal, [name]: value };
    });
  };
  const handleClosePartner = () => {
    setOpenPartner({ show: false, record: {} });
  };
  const scrollToDiv = (id) => {
    const element = document.getElementById(id.replace("#", ""));
    window.scrollTo({
      left: element?.offsetLeft,
      top: element?.offsetTop,
      behavior: "smooth",
    });
  };
  const reveal = () => {
    const reveals = document.querySelectorAll(".reveal");

    reveals.forEach((element) => {
      const windowHeight = window.innerHeight;
      const elementTop = element.getBoundingClientRect().top;
      const elementVisible = 100;

      if (elementTop < windowHeight - elementVisible) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", reveal);

    return () => {
      window.removeEventListener("scroll", reveal);
    };
  }, []);

  return (
    <div className="layout bglight pb-0">
      <div className="hero_section">
        <div className="hero_main position-relative">
          <div className="container_width h100 ">
            <img src={yolohimg} className="yolohimg" />
            <Grid container className=" h100 " style={{ alignItems: "end" }}>
              <Grid sm={12} md={0} lg={4} item={true} className=""></Grid>
              <Grid
                sm={12}
                md={6}
                lg={4}
                item={true}
                className="textcentermd"
                order={{ lg: 2, md: 2, sm: 3, xs: 2 }}
              >
                <img
                  src={hero}
                  alt=""
                  className="hero_image_main active reveal fade-bottom"
                />
              </Grid>
              <Grid
                sm={12}
                md={6}
                lg={4}
                item={true}
                className="hero_text h100 justifymd"
                order={{ lg: 2, md: 2, sm: 1, xs: 1 }}
                style={{ paddingBottom: "50px", justifyContent: "end" }}
              >
                <div className="widthauto">
                  <h1 className="active reveal fade-bottom">
                    Insurance
                    <br /> Dejumbled
                  </h1>
                  <p className="active reveal fade-bottom">
                    Let’s simplify it together.
                    <br /> Better coverage. Better value.
                    <br /> That’s the YOLOH way - Everywhere you are!
                  </p>
                </div>
              </Grid>
            </Grid>
            {/* <div className="bannercss">
              <div>
              <img
                  src={hero}
                  alt=""
                  className="hero_image_main active reveal fade-bottom"
                />
              </div>
              <div className="hero_text">
                  <h1 className="active reveal fade-bottom">
                    Insurance
                    <br /> Dejumbled
                  </h1>
                  <p className="active reveal fade-bottom">
                    Let’s simplify it together. For free.
                    <br /> Better coverage. Better value.
                    <br /> That’s the YOLOH way - Everywhere you are!
                  </p>
                </div>
            </div> */}
          </div>
        </div>
      </div>
      <section className="how_work_section">
        <div className="container_width">
          <Grid container spacing={5} sx={{ mb: "50px" }}>
            <Grid item sm={12} md={12} lg={12}>
              {/* <Grid item sm={12} md={6} lg={7}> */}
              <h1
                className="main_heading m-0 reveal fade-bottom"
                // style={{ maxWidth: "505px" }}
              >
                Amazingly Easy Insurance For All
              </h1>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              {/* <Grid item sm={12} md={6} lg={5}> */}
              <p className="how_work_section_p reveal fade-bottom">
                One place to shop, buy, store, manage and share. Highly biased
                toward taking care of YOU.
                <br />
                <strong> Honest. Trusted. Secure.</strong>
                <br /> Sit back, relax and enjoy the simplicity of savings.
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item sm={12} md={6} lg={4}>
              <div className="how_work_section_dark_box reveal fade-bottom">
                <div>
                  <h4>Effortless</h4>
                  <p>
                    Our tech geeks nailed it via the most advanced innovation
                    and data on the planet. Minimal effort required.
                  </p>
                </div>
                <img src={e1} alt="" />
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <div className="how_work_section_dark_box reveal fade-bottom">
                <div>
                  <h4>Always On</h4>
                  <p>
                    We never stop searching for ways to help you via better
                    coverage, rates, tools, tech, information, etc.
                  </p>
                </div>
                <img src={e2} alt="" />
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <div className="how_work_section_dark_box reveal fade-bottom">
                <div>
                  <h4>Value</h4>
                  <p>
                    Stop overpaying. Get the coverage you need and savings you
                    deserve.
                  </p>
                </div>
                <img src={e3} alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section style={{ background: "#fff", padding: "50px 0" }}>
        <div className="container_width">
          <Grid container spacing={5}>
            {/* <Grid container spacing={5} sx={{ pt: "100px" }}> */}
            <Grid item sm={12}>
              <h1
                className="main_heading reveal fade-bottom"
                style={{
                  maxWidth: "505px",
                  // margin: "auto",
                  display: "block",
                  // textAlign: "center",
                  marginBottom: "15px",
                }}
              >
                How It Works
              </h1>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ paddingLeft: 0 }}>
              <div className="Insurance_Marketplace_card values_card how_work_section_box_main reveal fade-bottom">
                <h3 style={{ marginTop: "23px", marginBottom: "10px" }}>
                  Share <br />
                  Your Needs
                </h3>
                <p>
                  Tell us your needs via our simple workflow. We also pull lots
                  of data to limit your manual inputs.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} style={{ paddingLeft: 0 }}>
              <div className="Insurance_Marketplace_card values_card how_work_section_box_main reveal fade-bottom">
                <h3 style={{ marginTop: "0px", marginBottom: "30px" }}>
                  The <br />
                  YOLOH Magic
                </h3>
                <p>
                  Our revolutionary platform uses innovation, data and
                  pre-vetted partners to search nationwide for the best options
                  and deals.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ paddingLeft: 0 }}>
              <div className="Insurance_Marketplace_card values_card how_work_section_box_main reveal fade-bottom">
                <h3 style={{ marginTop: "23px", marginBottom: "10px" }}>
                  Save <br /> Be on Autopilot
                </h3>
                <p>
                  You buy and save. We share more risk reduction ideas and will
                  also take great care of you at renewal, as markets change.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="service_section reveal fade-bottom" id="service">
        <div className="container_width">
          {/* <h1 className="sub_heading text-white reveal fade-bottom">
            Services
          </h1> */}
          <h1 className="main_heading text-white m-0 reveal fade-bottom">
            Services : What We Offer
          </h1>
          <Grid
            container
            style={{ marginTop: "35px", position: "relative" }}
            spacing={5}
          >
            {serviceType && (
              <div className="insurance_marketplace_box">
                <div className="insurance_marketplace">
                  <img src={serviceType?.image} alt="" />

                  <KeyboardArrowRightIcon
                    onClick={() => setServiceType(null)}
                    className="cursor-pointer"
                  />
                </div>
                <div className="devider" />
                <div className="insurance_marketplace_text">
                  <h3>{serviceType?.title}</h3>
                  <p>{serviceType?.describe_1}</p>
                  <p>{serviceType?.describe_2}</p>
                  <p>{serviceType?.describe_3}</p>
                  {serviceType?.describe_4 && <p>{serviceType?.describe_4}</p>}
                  {[
                    "Policy Vault",
                    "Insurance Marketplace",
                    "Seriously Smart Savings",
                    "Insurance Verification",
                  ].includes(serviceType?.title) && (
                    <div className="d-flex justify-end positionbtn">
                      <button
                        className="submit_button custombtn"
                        onClick={(event) => {
                          event?.stopPropagation();
                          if (
                            getLocalStorageValue("authUser") &&
                            getLocalStorageValue("authUserProfile")
                          ) {
                            if (serviceType?.title === "Policy Vault") {
                              navigate("dashboard");
                            } else if (
                              serviceType?.title === "Insurance Verification"
                            ) {
                              navigate("dashboard");
                            } else if (
                              serviceType?.title === "Seriously Smart Savings"
                            ) {
                              navigate("dashboard");
                            } else {
                              navigate("dashboard");
                            }
                          } else {
                            setLoginModal(true);
                          }
                        }}
                      >
                        Explore
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {servicesCard?.map((service, index) => (
              <Grid item sm={12} md={6} lg={3} key={index}>
                <div
                  className="offer_card reveal fade-bottom"
                  onClick={() => setServiceType(service)}
                >
                  <div
                    className="responsiveOfferModal"
                    onClick={() =>
                      setOpenOffer({ show: true, record: service })
                    }
                  ></div>
                  <img
                    src={service?.image}
                    alt=""
                    width={40}
                    className="dark_img"
                  />
                  <img
                    src={service?.darkimage}
                    alt=""
                    width={40}
                    className="light_img"
                  />
                  <h3>{service?.title}</h3>
                  <p>{service?.describe}</p>
                  <KeyboardArrowRightIcon />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
      <section className="how_work_section" id="lenders-servicers">
        <div className="container_width lenders_servicers_content">
          <Grid container className="aboutSectionInner">
            <Grid sm={12} md={6} item={true} className="lenders_servicers_Text">
              <h1 className="main_heading reveal fade-bottom">
                Lenders/Servicers
              </h1>
              <p className="reveal fade-bottom">
                Today, many organizations manually 'stare & compare' homeowner
                insurance (HOI) policies against their 1003 data, finding that
                roughly 70% of policies have errors. The re-work involves
                lenders, borrowers, insurance agents and carriers. Ouch!
              </p>
              <p className="reveal fade-bottom">
                This drives loan closing delays and negative borrower
                perceptions and is an inefficient and costly model.
              </p>
              <p className="reveal fade-bottom">
                Great news! Our proprietary technology solves this pain, and
                absolutely nobody else offers this.
              </p>
              <h4
                style={{
                  fontFamily: "jokker-bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setOpenWhatWeOffer({
                    show: true,
                    record: {
                      title: "Lenders/Servicers",
                      describes: [
                        "Today, many organizations manually 'stare & compare' homeowner insurance (HOI) policies against their 1003 data, finding that roughly 70% of policies have errors. The re-work involves lenders, borrowers, insurance agents and carriers. Ouch!",
                        "This drives loan closing delays and negative borrower perceptions and is an inefficient and costly model.",
                        "Great news! Our proprietary technology solves this pain, and absolutely nobody else offers this. No matter where insurance is purchased, YOLOH will:",
                        "1. Chase down all insurance documents for you.",
                        "2. Use Artificial Intel (AI) to automate and QC policies against your data.",
                        "3. Identify errors and provide a time/date stamped verification report — ‘Pass/Fail.’",
                        "4. If we sold the insurance policy, we immediately fix fails before you get the verification report. For policies sold elsewhere, you know exact failures and remedies to pursue with borrowers and/or agents.",
                        "Eliminate or reassign your fixed cost insurance resources. Instead, pay only for automated and QC'd verification reports delivered to you. We will also deliver the full insurance policy, if desired. Plus, there is no verification cost on transactions whereby your borrowers bought policies from our own online insurance marketplace or agents.",
                        "A solution well-suited for mortgage, home equity and/or any financial institution with asset-based lending that requires insurance. For banks, lenders, credit unions, loan servicers and more.",
                        "Budget and borrower friendly. RESPA compliant.",
                      ],
                    },
                  })
                }
              >
                Learn More
              </h4>
            </Grid>
            <Grid
              sm={12}
              md={6}
              item={true}
              className="lenders_servicers_image"
            >
              <img
                src={lendersServicers}
                alt="lenders_servicers_image"
                className="reveal fade-bottom"
              />
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="Insurance_Marketplace" id="insurance-marketplace">
        <div className="container_width">
          <h1 className="main_heading m-0 reveal fade-bottom">
            Insurance Marketplace
          </h1>
          <Grid container style={{ marginTop: "50px" }}>
            {insuranceMarketplace &&
              insuranceMarketplace.length > 0 &&
              insuranceMarketplace?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={index}
                  style={{ paddingLeft: "5px", paddingTop: "5px" }}
                >
                  <div className="Insurance_Marketplace_card values_card Insurance_Marketplace_card_new reveal fade-bottom">
                    <img src={item?.image} alt="" height={40} />
                    <h2 style={{ marginTop: "23px", marginBottom: "10px" }}>
                      {item?.title}
                    </h2>
                    <p style={{ marginBottom: "25px" }}>{item?.subTitle}</p>
                    <h4
                      style={{
                        fontFamily: "jokker-bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpen({ show: true, record: item })}
                    >
                      Learn More
                    </h4>
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </section>
      <section className="join_section" id="join-us">
        <div className="container_width">
          <h1 className="main_heading m-0 reveal fade-bottom">
            Why Partner With Us?
          </h1>
          <div className="partner_box_main">
            {partner &&
              partner?.length > 0 &&
              partner?.map((item, index) => (
                <div
                  className="values_card partner_box reveal fade-bottom"
                  key={index}
                >
                 <div className="flexibleCard">
                    <div>
                    <h2>{item?.title}</h2>
                    <p>{item?.describe}</p>
                    </div>
                    <h4
                      style={{
                        fontFamily: "jokker-bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setOpenPartner({ show: true, record: item })
                      }
                    >
                      Learn More
                    </h4>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <div className="aboutSection" id="about">
        <div className="container_width">
          <Grid container className="aboutSectionInner">
            <Grid sm={12} md={6} item={true} className="aboutText">
              {/* <h1 className="sub_heading">About</h1> */}
              <h1 className="main_heading reveal fade-bottom">About us</h1>
              <p className="reveal fade-bottom">
                We’re a dynamic team of insurance enthusiasts, tech visionaries,
                and creative problem solvers. Together, we’re on a mission to
                dejumble insurance and make it accessible to everyone.
              </p>
            </Grid>
            <Grid sm={12} md={6} item={true} className="about_image">
              <img src={aboutUs} alt="" className="reveal fade-bottom" />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item sm={12} md={6} lg={4} className="about_card_main">
              <div className="about_card reveal fade-bottom">
                <img src={target} alt="" width={40} />
                <h3>Purpose</h3>
                <p>
                  Our goal is to empower you, giving you the tools to take
                  control of your insurance and save money.
                </p>
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={4} className="about_card_main">
              <div className="about_card reveal fade-bottom">
                <img src={Vision} alt="" width={40} />
                <h3>Vision</h3>
                <p>
                  We believe in the power of simplicity. We make the complex
                  world of insurance easy to navigate, ensuring you have a clear
                  understanding of your coverage.
                </p>
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={4} className="about_card_main">
              <div className="about_card reveal fade-bottom">
                <img src={mission} alt="" width={40} />
                <h3>Mission</h3>
                <p>
                  In a world of rapid technological advancements, we stay at the
                  forefront of innovation, delivering better solutions for
                  customers, agents, and insurance carriers alike.
                </p>
              </div>
            </Grid>
          </Grid>
          {/* <Grid container style={{ padding: "90px 0" }}>
            <Grid item sm={12}>
              <h1 className="main_heading m-0">Our Core Values</h1>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <div className="values_card pr-75">
                <h2>Empowerment</h2>
                <p>
                  Our goal is to empower you, giving you the tools to take
                  control of your insurance and save money.
                </p>
              </div>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <div className="values_card pr-75 pl-75 border-l-r">
                <h2>Simplicity</h2>
                <p>
                  We make the complex world of insurance easy to navigate,
                  ensuring you have a clear understanding of your coverage.
                </p>
              </div>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <div className="values_card pl-75">
                <h2>Continuous Innovation</h2>
                <p>
                  We stay at the forefront of innovation, delivering better
                  solutions for customers, agents, and insurance carriers alike.
                </p>
              </div>
            </Grid>
          </Grid> */}
        </div>
      </div>
      <section className="partner">
        <div className="container_width">
          {/* <Grid container style={{ marginBottom: "100px" }} spacing={5}>
            <Grid item sm={12} md={12} lg={4}>
              <div className="team_desc">
                <h1 className="main_heading m-0">The Team</h1>
                <p className="p_tag" style={{ marginTop: "30px" }}>
                  As a collective, we bring an impressive 125+ years of combined
                  experience to the table, spanning the realms of insurance
                  domain knowledge, business strategy, technology, and
                  marketing.
                </p>
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <div className="team_sub_desc">
                <h2 className="card_title">
                  125+ years of combined experience
                </h2>
                <p className="p_tag" style={{ marginTop: "20px" }}>
                  As a collective, we bring an impressive 125+ years of combined
                  experience to the table, spanning
                </p>
              </div>
              <div className="mt-50 team_sub_desc">
                <h2 className="card_title">
                  125+ years of combined experience
                </h2>
                <p className="p_tag" style={{ marginTop: "20px" }}>
                  As a collective, we bring an impressive 125+ years of combined
                  experience to the table, spanning
                </p>
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
              <div className="team_sub_desc">
                <h2 className="card_title">
                  125+ years of combined experience
                </h2>
                <p className="p_tag" style={{ marginTop: "20px" }}>
                  As a collective, we bring an impressive 125+ years of combined
                  experience to the table, spanning
                </p>
              </div>
              <div className="mt-50 team_sub_desc">
                <h2 className="card_title">
                  125+ years of combined experience
                </h2>
                <p className="p_tag" style={{ marginTop: "20px" }}>
                  As a collective, we bring an impressive 125+ years of combined
                  experience to the table, spanning
                </p>
              </div>
            </Grid>
          </Grid> */}
          <Grid container>
            <Grid item sm={12} md={12} lg={12} className="partner_heading">
              <h1 className="main_heading m-0 text-center reveal fade-bottom">
                Our Partners
              </h1>
              <p className="p_tag text-center mt-30 reveal fade-bottom">
                We are thrilled to showcase the collaborative strength and
                shared vision that fuels YOLOH's success.
              </p>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "50px" }} spacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div className="partner_card reveal fade-bottom">
                <div className="partner_card_image">
                  <img src={partner1} alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div className="partner_card reveal fade-bottom">
                <div className="partner_card_image">
                  <img src={partner2} alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <div className="partner_card reveal fade-bottom">
                <div className="partner_card_image">
                  <img src={partner3} alt="" />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="contact_section_main" id="contact-us">
        <div className="container_width">
          <div className=" contact_section">
            <div
              className="promis_section"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div>
                <h1 className="main_heading m-0 text-white reveal fade-bottom">
                  Our Promise
                </h1>
                <p
                  className="p_tag text-white reveal fade-bottom"
                  style={{ marginTop: "30px" }}
                >
                  At YOLOH, our values drive our actions. We're here to empower,
                  simplify, and innovate, ensuring that insurance is no longer a
                  daunting experience. Join us as we redefine insurance for the
                  better.
                </p>
              </div>
              <img
                src={promise}
                className="reveal fade-bottom handwidth"
                alt=""
                style={{
                  marginLeft: "auto",
                  display: "block",
                  marginRight: "auto",
                }}
              />
            </div>
            <form className="form_section" onSubmit={handelConnectForm}>
              <h1 className="main_heading m-0 reveal fade-bottom">
                Let's Connect!
              </h1>
              {/* <div
                className={`form_fild reveal fade-bottom active ${error?.name ? "error_fields" : ""
                  }`}
              >
                <p>
                  Name<span className="mandatory">&nbsp;*</span>
                </p>
                <input
                  type="text"
                  name="name"
                  value={value?.name}
                  onChange={handelConnectField}
                />
                <p>{error?.name}</p>
              </div> */}
              <TextField
                id={"textField_Name"}
                autoComplete="nope"
                type={"text"}
                name="name"
                label="Name"
                variant="outlined"
                margin="none"
                color="secondary"
                value={value?.name}
                error={!!error?.name}
                className="reveal fade-bottom active paddingforce"
                onChange={handelConnectField}
                placeholder="Name"
                fullWidth
                inputProps={{
                  classes: { focused: "hello-world", root: "world" },
                }}
                helperText={error?.name ? error?.name : null}
              />
              {/* <div
                className={`form_fild reveal fade-bottom active ${error?.email ? "error_fields" : ""
                  }`}
              >
                <p>
                  Email<span className="mandatory">&nbsp;*</span>
                </p>
                <input
                  type="text"
                  name="email"
                  value={value?.email}
                  onChange={handelConnectField}
                />
                <p>{error?.email}</p>
              </div> */}
              <TextField
                id={"textField_email"}
                autoComplete="nope"
                type={"text"}
                name="email"
                label="Email"
                variant="outlined"
                margin="none"
                color="secondary"
                className="reveal fade-bottom active paddingforce"
                value={value?.email}
                error={!!error?.email}
                onChange={handelConnectField}
                placeholder="email"
                fullWidth
                inputProps={{
                  classes: { focused: "hello-world", root: "world" },
                }}
                helperText={error?.email ? error?.email : null}
              />
              {/* <div
                className={`form_fild reveal fade-bottom active ${error?.phone_number ? "error_fields" : ""
                  }`}
              >
                <p>
                  Phone Number<span className="mandatory">&nbsp;*</span>
                </p>
                <input
                  type="text"
                  name="phone_number"
                  value={value?.phone_number}
                  onChange={handelConnectField}
                />
                <p>{error?.phone_number}</p>
              </div> */}
              <TextField
                id={"textField_phone_number"}
                autoComplete="nope"
                type={"text"}
                name="phone_number"
                label="Phone Number"
                variant="outlined"
                margin="none"
                color="secondary"
                value={value?.phone_number}
                error={!!error?.phone_number}
                className="reveal fade-bottom active paddingforce"
                onChange={handelConnectField}
                placeholder="Phone Number"
                fullWidth
                inputProps={{
                  classes: { focused: "hello-world", root: "world" },
                }}
                helperText={error?.phone_number ? error?.phone_number : null}
              />
              {/* <div className="form_fild reveal fade-bottom active"> */}
              {/* <p style={{ marginBottom: "10px" }}>I want to</p> */}
              <FormControl
                fullWidth
                className={`select-box-landing reveal fade-bottom active paddingforce`}
              >
                <InputLabel id={"demo-simple-select-standard-label"}>
                  I want to
                </InputLabel>
                <Select
                  // IconComponent={() => (
                  //   <ExpandMoreIcon
                  //     style={{
                  //       color: "#09143a",
                  //       fontSize: "30px",
                  //     }}
                  //   />
                  // )}
                  labelId="demo-simple-select-standard-label"
                  value={value?.category}
                  label="I want to"
                  name="category"
                  onChange={handelConnectField}
                  className="reveal fade-bottom active"
                  variant="outlined"
                  // className="contact-select-box"
                  inputProps={{
                    classes: {
                      focused: "hello-world",
                      root: "world",
                    },
                  }}
                  displayEmpty
                >
                  {/* <MenuItem value="" disabled>
                    Select an option
                  </MenuItem> */}
                  <MenuItem value="Ask a Question">Ask a Question</MenuItem>
                  <MenuItem value="Partner with YOLOH">
                    Partner with YOLOH
                  </MenuItem>
                  <MenuItem value="Request a Demo">Request a Demo</MenuItem>
                  <MenuItem value="Raise a Ticket">Raise a Ticket</MenuItem>
                </Select>
              </FormControl>
              {/* </div> */}
              {/* <div className="form_fild reveal fade-bottom active">
                <p >Description</p>
                <textarea
                  rows={3}
                  name="description"
                  value={value?.description}
                  onChange={handelConnectField}
                />
              </div> */}
              <TextField
                id={"textField_description"}
                autoComplete="nope"
                type={"text"}
                name="description"
                label="Description"
                variant="outlined"
                margin="none"
                color="secondary"
                className="reveal fade-bottom active paddingforce"
                value={value?.description}
                error={!!error?.description}
                onChange={handelConnectField}
                placeholder="Description"
                fullWidth
                inputProps={{
                  classes: { focused: "hello-world", root: "world" },
                }}
                helperText={error?.description ? error?.description : null}
              />
              <button
                className="submit_button submithover reveal fade-bottom active"
                type="submit"
                style={{ display: "flex", margin: 0, alignItems: "center" }}
                disabled={loader}
              >
                Submit
                {loader && <CircularProgress color="inherit" size={25} />}
              </button>
            </form>
          </div>
        </div>
      </section>
      <Modal
        open={open?.show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="landing-page-modal"
      >
        {open?.record && (
          <div
            className="learnMoreModel"
            style={{ justifyContent: "space-between" }}
          >
            <div className="close-icon">
              <CloseIcon onClick={handleClose} />
            </div>
            <div className="contentTitleBox ">
              <div className="largeiconcard ">
                <img
                  src={open?.record?.image}
                  alt=""
                  height={40}
                  style={{ marginBottom: "15px" }}
                />
              </div>
            </div>

            <div className="deviders ">
              <div className="contentBox ">
                <h2>{open?.record?.title}</h2>
                <p>{open?.record?.subTitle}</p>

                {open?.record?.description_1 && (
                  <p>{open?.record?.description_1}</p>
                )}
                {open?.record?.description_2 && (
                  <p>{open?.record?.description_2}</p>
                )}
                {open?.record?.description_3 && (
                  <p>{open?.record?.description_3}</p>
                )}
                <div className="d-flex justify-end">
                  <button
                    className="submit_button custombtn "
                    style={{ padding: "0" }}
                    onClick={(event) => {
                      event?.stopPropagation();
                      if (
                        getLocalStorageValue("authUser") &&
                        getLocalStorageValue("authUserProfile")
                      ) {
                        navigate("dashboard");
                      } else {
                        setLoginModal(true);
                      }
                    }}
                  >
                    Explore
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        open={openWhatWeOffer?.show}
        onClose={handleCloseOffer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="landing-page-modal"
      >
        {openWhatWeOffer?.record && (
          <div className="learnMoreModel position-relative">
            <div className="close-icon" style={{ top: "20px", right: "20px" }}>
              <CloseIcon onClick={handleCloseOffer} />
            </div>
            <div className="contentTitleBox" style={{ display: "block" }}>
              <h2>{openWhatWeOffer?.record?.title}</h2>
            </div>
            <div className="contentBox">
              {openWhatWeOffer?.record?.describes?.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
        )}
      </Modal>

      <Modal
        open={openPartner?.show}
        onClose={handleClosePartner}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="landing-page-modal"
      >
        {openPartner?.record && (
          <div className="learnMoreModel flex-wrap">
            <div className="close-icon">
              <CloseIcon onClick={handleClosePartner} />
            </div>
            <div></div>
            <div
              className="contentTitleBox  w-100"
              style={{ marginBottom: "20px" }}
            >
              <h2>{openPartner?.record?.title}</h2>
              {/*  <button className="submit_button">Yes, I want to partner</button> */}
            </div>
            <div className="contentBox  w-100">
              {openPartner?.record?.description_1 && (
                <p>{openPartner?.record?.description_1}</p>
              )}
              {openPartner?.record?.description_2 && (
                <p>{openPartner?.record?.description_2}</p>
              )}
              {openPartner?.record?.description_3 && (
                <p>{openPartner?.record?.description_3}</p>
              )}
              {openPartner?.record?.description_4 && (
                <p>{openPartner?.record?.description_4}</p>
              )}
              {openPartner?.record?.note &&
                openPartner?.record?.note?.length > 0 &&
                openPartner?.record?.note?.map((item, index) => (
                  <div key={index}>
                    <h2 style={{ fontFamily: "jokker-bold" }}>{item?.title}</h2>
                    <ul>
                      {item?.step &&
                        item?.step?.length > 0 &&
                        item?.step?.map((val, i) => <li key={i}>{val}</li>)}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        open={openOffer?.show}
        onClose={() => setOpenOffer({ show: false, record: {} })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="landing-page-modal"
      >
        {openOffer?.record && (
          <div className="learnMoreModel ">
            <div className="contentTitleBox">
              <div>
                <h2>{openOffer?.record?.title}</h2>
              </div>
            </div>
            <div className="contentBox">
              {/* {openOffer?.record?.describe && (
                <p>{openOffer?.record?.describe}</p>
              )} */}
              {openOffer?.record?.describe_1 && (
                <p>{openOffer?.record?.describe_1}</p>
              )}
              {openOffer?.record?.describe_2 && (
                <p>{openOffer?.record?.describe_2}</p>
              )}
              {openOffer?.record?.describe_3 && (
                <p>{openOffer?.record?.describe_3}</p>
              )}
              {openOffer?.record?.describe_4 && (
                <p>{openOffer?.record?.describe_4}</p>
              )}
            </div>
            {[
              "Policy Vault",
              "Insurance Marketplace",
              "Seriously Smart Savings",
              "Insurance Verification",
            ].includes(serviceType?.title) && (
              <div className="d-flex justify-end positionbtn">
                <button
                  className="submit_button custombtn"
                  style={{ bottom: "-20px", right: "-40px" }}
                  onClick={(event) => {
                    event?.stopPropagation();
                    if (
                      localStorage.getItem("authUser") &&
                      localStorage.getItem("authUserProfile")
                    ) {
                      if (serviceType?.title === "Policy Vault") {
                        navigate("policy-vault");
                      } else if (
                        serviceType?.title === "Insurance Verification"
                      ) {
                        navigate("insurance-verification-summary");
                      } else if (
                        serviceType?.title === "Seriously Smart Savings"
                      ) {
                        navigate("insurances");
                      } else {
                        navigate("insurances");
                      }
                    } else {
                      setLoginModal(true);
                    }
                  }}
                >
                  Explore
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
      {/* <Modal
          open={openWhatWeOffer?.show}
          onClose={handleCloseOffer}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="landing-page-modal"
        >
          {openWhatWeOffer?.record && (
            <div className="learnMoreModel">
              <div className="contentTitleBox">
                <div>
                  <h2>{openWhatWeOffer?.record?.title}</h2>
                </div>
              </div>
              <div className="contentBox">
                {openWhatWeOffer?.record?.describe && (
                  <p>{openWhatWeOffer?.record?.describe}</p>
                )}
                {openWhatWeOffer?.record?.describe_1 && (
                  <p>{openWhatWeOffer?.record?.describe_1}</p>
                )}
                {openWhatWeOffer?.record?.describe_2 && (
                  <p>{openWhatWeOffer?.record?.describe_2}</p>
                )}
              </div>
            </div>
          )}
        </Modal> */}
      {/* <Modal
        open={openPartner}
        onClose={handleClosePartner}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="learnMoreModel">
          <div></div>
          <div className="contentTitleBox">
            <h2>Banks, Lenders & Servicers</h2>
            <button className="submit_button">Yes, I want to partner</button>
          </div>
          <div className="contentBox">
            <p>
              Get highly qualified leads, as our subscriber community has
              confirmed they are ‘in the market.’ They like and trust us to act
              in their best interest.
            </p>
            <p>
              We will limit the number of leads we pass on to our insurance
              partners, so you won’t be competing with dozens. The more
              successful you are, the more we prosper. We understand your needs
              and expectations - having built the platform with you in mind.
            </p>
            <p>
              The Tiered Model: You subscribe to get qualified leads and reply
              with policy quotes, if desired. We get modest compensation on the
              volume of leads you get and win.
            </p>
          </div>
        </div>
      </Modal> */}
      <Modal
        keepMounted={false}
        open={loginModal}
        // onClose={closeLoginModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box>
          <Login setLoginModal={setLoginModal} />
        </Box>
      </Modal>
    </div>
  );
};

export default Lending;
